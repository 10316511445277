//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import CoLoginForm from '~/components/common/CoLoginForm'
import CoSignupForm from '~/components/common/CoSignupForm'

export default {
    name: 'PageLogin',
    components: { CoLoginForm, CoSignupForm },
    middleware: 'auth_login',
    head () {
        return {
            title: 'Log in to Udimi',
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: 'Udimi solo ads login. Log in to your account to buy and sell solo ads'
                },
                {
                    hid: 'keywords',
                    name: 'keywords',
                    content: 'Udimi, Udimi login, Udimi signup, solo ads, Udimi solo ads'
                }
            ],
            link: [
                { rel: 'canonical', hid: 'canonical', href: `${this.$config.siteProtocol}://${this.$config.siteHost}/login` }
            ]
        }
    },
    computed: {
        ...mapGetters(['isMobile']),
        isLoginAndSignupLayout () {
            return this.$route.params.showRedirectMessage
        }
    }
}
