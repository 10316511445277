//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { VueRecaptcha } from 'vue-recaptcha'
import { AUTH_MODE_DEVELOPMENT, REDIRECT_URLS } from '~/constants/auth'
import GoogleAuth from '~/components/elements/ElGoogleAuth.vue'
import { CLIENT_TIMEZONE } from '~/constants'

export default {
    name: 'CoLoginForm',
    components: { VueRecaptcha, GoogleAuth },
    props: {
        isLoginAndSignupLayout: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        login: '',
        password: '',
        captcha: '',
        isNeedCaptcha: false,
        errors: {
            login: '',
            password: ''
        },
        loading: false
    }),
    fetchOnServer: false,
    async fetch () {
        const captcha = await this.$api.get('guests/auth/captcha')
            .catch(err => this.$handleErrors(err))
        if (!captcha) { return {} }
        this.isNeedCaptcha = captcha.data.is_need
    },
    computed: {
        ...mapGetters(['isMobile'])
    },
    methods: {
        submit () {
            if (this.localValidate()) {
                this.loading = true
                // Перед сабмитом нужно спрятать капчу, если в ней поставили галочку (this.captcha=true).
                // Это необходимо для того, чтобы после сабмита капчи уже не было. Если нужно будет, то в .catch() ее включат снова
                if (this.captcha) {
                    this.isNeedCaptcha = false
                }
                this.$api.post('guests/auth/login', {
                    login: this.login,
                    password: this.password,
                    fp_token2: this.$fingerprint,
                    captcha: this.captcha,
                    timezone: CLIENT_TIMEZONE,
                    saved_request: this.$route.params?.savedRequest
                })
                    .then((res) => {
                        if (this.$config.authMode === AUTH_MODE_DEVELOPMENT) {
                            this.$store.dispatch('cookies/setAuthCookie', {
                                id: res.data.id,
                                email: res.data.login,
                                token: res.data.token,
                                fingerprint: res.data.fp_token2
                            })
                        }
                        const serverRedirect = res.data.redirect && REDIRECT_URLS[res.data.redirect]
                            ? REDIRECT_URLS[res.data.redirect]
                            : REDIRECT_URLS.mypage
                        window.location.href = this.$route.params?.redirect || serverRedirect
                    })
                    .catch(err => this.$handleErrors(err, async (message, errors) => {
                        let hasFieldError = false
                        let hasCaptchaError = false
                        for (const key in errors) {
                            if (key === 'captcha') {
                                hasCaptchaError = true
                            }
                            if (this.errors[key] !== undefined) {
                                this.errors[key] = errors[key][0]
                                hasFieldError = true
                            }
                        }

                        // Если капчи не было (например, была убрана перед сабмитом или вообще ее еще не было), то нужно
                        // её вывести, избежав вывод текста ошибки "Captcha required" в this.$alert(_message) ниже в else if.
                        // А если капча уже была (например без галки в момент старта сабмита), то выведется ошибка
                        // this.$alert(_message) что "Captcha required" (отработает else if)
                        if (hasCaptchaError && !this.isNeedCaptcha) {
                            this.isNeedCaptcha = true
                        } else if (!hasFieldError) {
                            await this.$confirm({ text: message, noLabel: false })
                                .catch(() => {})
                        }

                        this.loading = false
                    }))
                    .finally(() => { this.captcha = '' })
            }
        },
        localValidate () {
            let result = true
            if (this.login.length === 0 || this.password.length === 0) {
                result = false
                if (this.login.length === 0) {
                    this.showError('login', 'Email is required')
                }
                if (this.password.length === 0) {
                    this.showError('password', 'Password is required')
                }
            }
            return result
        },
        showError (name, error) {
            this.errors[name] = error
        },
        hideError (name) {
            this.errors[name] = ''
        },
        verifyCaptcha (token) {
            this.captcha = token
        },
        errorCaptcha () {
            this.$alert('You have an inactive internet connection or other unexpected error. Please check your connection and try again.')
        },
        expiredCaptcha () {
            this.captcha = ''
        }
    }
}
